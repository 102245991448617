<template>
    <div class="lsc-home">
        <kds-header :hideHamburgerMenu="true" app-name="Line Optimizer Service Center">
            <kds-tooltip slot="menu-item">
                <a :href="this.globalStore.accountConfigUrl" target="_blank">
                    <kds-avatar
                        size="m"
                        :name="globalStore.username"
                    ></kds-avatar>
                </a>
                <div slot="tooltip">{{ globalStore.username }}</div>
            </kds-tooltip>
            <kds-button
                color="secondary"
                slot="menu-item"
                v-on:click="logoutUser()"
            >
                Logout
            </kds-button>
        </kds-header>
        <div class="node-nav">
            <draggable
                v-model="nodes"
                v-bind="dragOptions"
                group="enterprise"
                @start="drag = true"
                @end="drag = false"
                item-key="id"
                :disabled="!globalStore.isAdmin || globalStore.dragDisabled"
                @change="nodesRearranged"
            >
                <template #item="{ element }">
                    <div>
                        <LscOrgNode
                            v-bind:nodeId="element.id"
                            v-bind:name="element.name"
                            v-bind:type="element.type"
                            v-bind:isInitialActive="
                                $route.params.nodeId
                                    ? $route.params.nodeId
                                    : nodes[0].id
                            "
                            v-bind:spacer="0"
                            v-bind:parent-perform-load="performLoad"
                        />
                    </div>
                </template>
            </draggable>

            <LscLoadingSpinner v-if="loadingSpinner.nodes" class="kds-m-5" />

            <div class="centered">
                <kds-button
                    color="tertiary"
                    @click="openAddEnterprisePopup"
                    v-bind:class="{ hidden: !globalStore.isAdmin }"
                    class="kds-m-2 add-enterprise"
                    >Add Enterprise</kds-button
                >
            </div>
        </div>
        <div class="node-support">
            <div><a href="mailto:support.lineoptimizer@koerber.com">support.lineoptimizer@koerber.com</a></div>
            <div class="commit-version">{{ currentCommitVersion }}</div>
        </div>
        <div class="node-detail"><LscActiveNodeView /></div>

        <LscPopup
            v-bind:popupOpen="popups.addEnterpriseOpen"
            v-bind:on-close="
                () => {
                    popups.addEnterpriseOpen = !popups.addEnterpriseOpen;
                }
            "
        >
            <template v-slot:header>Add Enterprise</template>
            <template v-slot:content>
                <kds-input
                    class="kds-m-2"
                    label="Enterprise Name"
                    name="name"
                    :value="form.name"
                    @input="form.name = $event.target.value"
                ></kds-input>

                <kds-dropdown
                    class="kds-m-2"
                    label="Roll Name"
                    name="roll_name"
                    :value="[form.roll_name]"
                    @input="form.roll_name = $event.target.value[0]"
                    :options="availableRolls"
                ></kds-dropdown>

                <p class="kds-caption">
                    if the required roll is not visible - check if your user has
                    the role and try to log out and log in again!
                </p>

                <kds-button @click="submitAddEnterprise" class="kds-m-2"
                    >Add</kds-button
                >
            </template>
        </LscPopup>
    </div>
</template>

<script>
// @ is an alias to /src
import { store } from '@/lib/globalStore';
import LscActiveNodeView from '@/components/LscStructure/LscActiveNodeView.vue';
import nodeHandler from '@/lib/nodeHandler';
import rollHandler from '@/lib/rollHandler';
import LscOrgNode from '@/components/LscStructure/LscOrgNode.vue';
import LscPopup from '@/components/LscStructure/LscPopup.vue';
import LscLoadingSpinner from '@/components/LscStructure/LscLoadingSpinner.vue';
import { commitVersion } from '@/config/config.js';

import draggable from 'vuedraggable';

export default {
    data() {
        return {
            globalStore: store.state,
            navStateOpen: false,
            nodes: null,
            currentCommitVersion: commitVersion,
            popups: {
                addEnterpriseOpen: false
            },
            form: {
                name: '',
                roll_name: 'lsc-user',
                node_id: null,
                is_admin: false
            },
            loadingSpinner: {
                nodes: false
            },
            availableRolls: [
                { value: 'lsc-user', label: 'lsc-user' },
                { value: 'lsc-admin', label: 'lsc-admin' }
            ]
        };
    },
    name: 'LscHome',
    components: {
        LscOrgNode,
        LscPopup,
        LscActiveNodeView,
        LscLoadingSpinner,
        draggable
    },
    created: function () {
        this.getUserInfo(); // TODO: move to global location
        let tmpDropdownRoles = [];
        try {
            const kcRoles =
                this.keycloak.realmAccess.roles;
            
            tmpDropdownRoles = kcRoles.filter((role) => {
                return role.startsWith('lsc-');
            }).map((role) => {
                return {
                    value: role,
                    label: role
                };
            });
        } catch (e) {
            console.log(e);
        }

        if (tmpDropdownRoles.length > 0) {
            this.availableRolls = tmpDropdownRoles;
        }

        this.performLoad();
    },
    methods: {
        // TODO move that somewhere?
        getUserInfo() {
            if (this.keycloak.authenticated) {
                this.keycloak.loadUserProfile().then((res) => {
                    res.is_admin = this.keycloak.realmAccess ? this.keycloak.realmAccess.roles.includes('lsc-admin') : false;
                    store.setUser(res);
                });
            } else {
                console.log('not authenticated');
            }
        },
        logoutUser() {
            this.keycloak.logout();
        },
        toggleMenu() {
            this.navStateOpen = !this.navStateOpen;
        },
        openAddEnterprisePopup() {
            this.popups.addEnterpriseOpen = !this.popups.addEnterpriseOpen;
        },
        nodesRearranged() {
            nodeHandler
                .rearrangeNodes(this.nodes)
                .catch(function (error) {
                    console.log(error);
                });
        },
        submitAddEnterprise() {
            if (this.form.roll_name === 'lsc-user') {
                if (!confirm('EVERY user will have access to this node if the lsc-user role is set! Do you want to continue?')) {
                    return
                }
            }
            this.popups.addEnterpriseOpen = false;
            // create enterprise
            nodeHandler
                .createNode(null, '', this.form)
                .then((response) => {
                    this.form.node_id = response.data.id;

                    // add roll
                    rollHandler.createRoll(this.form).then(() => {
                        this.performLoad();
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        performLoad() {
            this.loadingSpinner.nodes = true;
            nodeHandler
                .allEnterprises()
                .then((response) => {
                    this.nodes = response.data;
                    this.loadingSpinner.nodes = false;
                })
                .catch(function (error) {
                    if (
                        this &&
                        this.loadingSpinner !== undefined &&
                        this.loadingSpinner !== null
                    ) {
                        this.loadingSpinner.nodes = false;
                    }
                    console.log(error);
                });
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    }
};
</script>

<style scoped>
/* DRAG STYLES */

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

.lsc-home {
    display: grid;
    grid-template-columns: 2fr 8fr min-content;
    grid-template-rows: min-content 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'header header' 'node-nav node-detail' 'support node-detail';
    height: 100%;
}

.node-support {
    grid-area: support;
    font-size: .8rem;
    background-color: var(--kds-color-atmo3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}
.node-support a {
    color: var(--kds-color-acce1);
    text-decoration: none;
}
.commit-version {
    font-size: .6rem;
}
kds-header {
    grid-area: header;
}

.nav-bar {
    grid-area: nav-bar;
    padding: 0;
}

.node-nav {
    grid-area: node-nav;
    background-color: var(--kds-color-atmo3);
    overflow-y: scroll;
    /*height: 86vh;*/
}

.node-detail {
    grid-area: node-detail;
    display: block;
    height: 100%;
}

.mobile-menue {
    display: none;
}

.add-enterprise.hidden {
    display: none;
}
</style>

<style>
/*
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#app,
#app > div {
    height: 100%;
}*/
</style>
