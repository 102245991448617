<template>
    <div
        class="build"
        v-bind:class="{ hidden: !globalState.isAdmin || parentNode === null }"
    >
        <div class="details-navbar navbar"></div>
        <div class="details-content">
            <div
                class="add-build"
                v-bind:class="{
                    hidden: !globalState.isAdmin || parentNode === null
                }"
            >
                <div class="info upload">
                    <div class="name kds-subtitle2">Build File</div>
                    <div class="description">
                        <input
                            id="file-upload"
                            type="file"
                            v-on:change="onFileChange"
                        />
                    </div>
                </div>

                <label
                    for="file-upload"
                    class="custom-file-upload kds-m-2"
                    v-if="!isFileChoosen()"
                >
                    Choose from Filesysten
                </label>

                <kds-button
                    class="kds-m-2"
                    @click="resetUplads()"
                    v-if="isFileChoosen()"
                    >reset uploaded</kds-button
                >

                {{
                    this.selectedFile !== '' && this.selectedFile !== undefined
                        ? 'file from Filesystem'
                        : ''
                }}

                <b>
                    {{
                        this.selectedFile !== '' &&
                        this.selectedFile !== undefined
                            ? this.selectedFile.name
                            : ''
                    }}
                </b>

                <kds-dropdown
                    :value="[form.product_name]"
                    @input="form.product_name = $event.target.value[0]"
                    class="kds-mt-3"
                    label="Product Name"
                    placeholder="Make selection"
                    name="product_name"
                    :options="dropdownValues"
                ></kds-dropdown>

                <kds-input
                    label="Product Version"
                    name="version_product"
                    :value="form.version_product"
                    @input="form.version_product = $event.target.value"
                ></kds-input>
                <kds-input
                    v-if="form.product_name !== 'Lion.Design.'"
                    label="Project Version"
                    name="version_project"
                    :value="form.version_project"
                    @input="form.version_project = $event.target.value"
                ></kds-input>

                <div>
                    <div class="kds-subtitle2">Release Date</div>
                    <input 
                        type="date" 
                        :value="form.release_date.split('T')[0]"
                        @input="form.release_date = $event.target.value"
                    >
                </div>

                <kds-checkbox
                    class="kds-m-5"
                    label="is Expired"
                    value="expired"
                    :checked="form.is_expired ? '' : null"
                    @input="form.is_expired = $event.target.isChecked"
                ></kds-checkbox>

                <kds-checkbox
                    class="kds-m-5"
                    label="is Release"
                    value="release"
                    :checked="form.is_release ? '' : null"
                    @input="form.is_release = $event.target.isChecked"
                ></kds-checkbox>

                <div class="submit">
                    <kds-button
                        class="kds-m-2"
                        @click="submit"
                        :disabled="!selectedFile"
                        >Upload Build</kds-button
                    >
                </div>

                <div class="progress-bar-cont">
                    <div
                        v-if="progress"
                        class="progress-bar"
                        :style="{ width: progress }"
                    >
                        {{ progress }}
                    </div>
                </div>

                Remaining Upload Time: {{ remainingUploadTime }}
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import buildHandler from '@/lib/buildHandler';

export default {
    data() {
        return {
            globalState: store.state,
            selectedFile: '',
            progress: null,
            uploadStartTime: 0,
            remainingUploadTime: 0,
            folderNodes: [],
            dropdownValues: [
                { value: 'Lion', label: 'Lion' },
                { value: 'Lion.Connect.', label: 'Lion.Connect.' },
                { value: 'Lion.Detect.', label: 'Lion.Detect.' },
                { value: 'Lion.Design.', label: 'Lion.Design.' },
                { value: 'Lion.AR.', label: 'Lion.AR.' }
            ],
            form: {},
            templateForm: {
                product_name: 'Lion',
                file_path: '',
                version_product: 'V 1.0.0',
                version_project: 'V 0.0.1',
                size: 0,
                is_expired: false,
                is_release: true,
                md5: '',
                mimetype: '',
                release_date: ""
            }
        };
    },
    name: 'LscCreateBuild',
    props: {
        parentNode: String
    },
    created: function () {
        this.templateForm.release_date = new Date().toISOString().split("T")[0];
        this.resetInputs();
    },
    methods: {
        resetInputs() {
            this.resetUplads();
            this.form = this.templateForm;
            this.progress = null;
        },
        resetUplads() {
            this.selectedFile = '';
        },
        isFileChoosen() {
            return (this.selectedFile !== '' && this.selectedFile !== undefined);
        },
        onFileChange(e) {
            const selectedFile = e.target.files[0]; // accessing file
            this.selectedFile = selectedFile;
        },
        submit() {
            if (this.selectedFile !== '' && this.selectedFile !== undefined) {
                this.submitUploadedBuild();
            }
        },
        submitUploadedBuild() {
            this.uploadStartTime = new Date().getTime();

            buildHandler
                .uploadBuild(this.selectedFile, this.onProgress)
                .then((res) => {
                    console.log(res.data);
                    // file is uploaded
                    this.form.size = res.data.size;
                    this.form.file_path = res.data.filename;
                    this.form.mimetype = res.data.mimetype;
                    this.form.md5 = res.data.md5;

                    buildHandler
                        .createBuild(this.parentNode, this.form)
                        .then((build) => {
                            console.log(build);
                            this.emitter.emit('lsc:event:newBuildAdded');
                            this.resetInputs();
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onProgress(ProgressEvent) {
            let progress =
                Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
                '%';
            this.progress = progress;

            const remainingSecs = parseInt(
                (((new Date().getTime() - this.uploadStartTime) /
                    ProgressEvent.loaded) *
                    (ProgressEvent.total - ProgressEvent.loaded)) /
                    1000
            );

            var date = new Date(0);
            date.setSeconds(remainingSecs); // specify value for SECONDS here
            this.remainingUploadTime = date.toISOString().substring(11, 19);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'name'
        'description';

    float: left;
    font-size: 0.8rem;
    font-size: 0.8rem;
    text-align: left;
    width: 30%;
    margin: 0.5rem;
}

.info.upload {
    width: 50%;
    display: none;
}

.custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0.58em 1.72em;
    user-select: none;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: 700;
    border: 2px solid var(--kds-color-acce2);
    background: 0px 0px;
    color: var(--kds-color-acce2);
    border-radius: 33px;
    display: block;
    float: left;
}

.custom-file-upload:hover {
    border-color: var(--kds-color-sema7);
    background: var(--kds-color-sema7);
    color: var(--kds-color-acce1);
}

.name {
    grid-area: name;
    font-weight: bold;
}

.description {
    grid-area: description;
    font-size: 0.8rem;
}

.progress-bar-cont {
    width: 100%;
    float: left;
}

.progress-bar {
    display: block;
    float: left;
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 0.5rem;
}

.folder-container {
    overflow-y: scroll;
    max-height: 60vh;
    display: block;
}
</style>

<style>
.navbar > .nav-item {
    display: inline-block;
    font-weight: bold;
    padding: 0 1rem;
    cursor: pointer;
}

.navbar > .nav-item.active {
    text-decoration: underline;
}
.navbar > .nav-item.disabled {
    color: #a0a0a0;
    cursor: unset;
}
</style>
